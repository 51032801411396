<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-lg-12">
                            <AdminWidget12></AdminWidget12>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminWidget12 from "@/view/content/widgets/admin/Widget12.vue";

export default {
    name: 'corporatesignup',
    components: {
        AdminWidget12
    },
}
</script>
